import React from 'react';
import Typed from 'react-typed';

const About = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about.svg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Blockchain Solutions <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Application Specific EVMs.",
                                            "Enterprise-Grade EVMs.",
                                            "Private or Public Networks.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Cipher Labs has the technical expertise combined with the resourcefulness necessary for launching Private and Public Ethereum-compatable blockchain networks. </p>
                                <p>We can deploy Public or Private EVM Blockchains in either in a Permissionless or Permissioned network configurations with custom Gas Fees, Block Rewards and Validator rules.</p>
                                <p>Our blockchain solutions use Proof of Authority (PoA) as consensus mechanism. In PoA, Validator Nodes, not miners, validate and create new blocks. This makes PoA a very Eco-friendly solution.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/blockchains"><span>More Details</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default About
