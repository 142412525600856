import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionBD = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What is PoA? How does it work?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Proof-of-authority (PoA) is a consensus system that allows only authorized entities to validate transactions in a blockchain network. PoA blockchains are secured by the validating nodes that are vetted and selected as trustworthy entities. The Proof of Authority model relies on a limited number of block validators and this is what makes it a highly scalable system. Blocks and transactions are verified by pre-approved participants, who act as moderators of the system.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Can I use Metamask and TrustWallet?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Yes your chain will have its own unique Chain ID and own RPC nodes that are fully compatable with Metamask, TrustWallet and most other Web3 wallet interfaces.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Is there a Minimum or Maximum number of Validators?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>The minimum requirment is 4 validators to be Byzantine fault tolerant. For QBFT, the time to add new blocks increases as the number of validators increases. A maximum of 30 validators should be maintained for optimal performace.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What about Proof of Stake?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Staking and Rewards smart contracts can be pre-deployed on your network but proof of stake (PoS) as a consensus protocol is not supported at this time.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Do you offer on going support?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, We offer a maintaince and monitoring services to keep your blockchain running and updated. We also offer full stack Blockchain-As-A-Service contracts.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionBD

