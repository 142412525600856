import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import AboutTwo from "../elements/about/AboutTwo";


import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";




const Auditing = () => {
    return (
        <>
            <SEO title="Smart Contract Auditing" />
            <main className="page-wrapper">
                
                <Header btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-750">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    
                                    <h1 className="title theme-gradient display-one">SMART CONTRACT AUDITING.</h1>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Line by Line Manual Auditing.</li>
                                        <li><span className="icon"><FiCheck /></span> Static Analysis, Dynamic Analysis and Symbolic Execution.</li>
                                        <li><span className="icon"><FiCheck /></span> Full Reporting and Certification.</li>
                                    </ul>
                                    <div className="button-group mt--40">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://cal.com/cipherlabs">Get Started <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                                <div className="frame-image">
                                    <img src="./images/about/about-3.png" alt="solidity audits" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">In 2022 the estimated amount of funds lost to smart contract hacks totaled $2.7 billion.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Once you write the smart contract to the blockchain, it is impossible to change the code. Deploying smart contracts without proper audits could result in untoward circumstances such as discrepancies in the desired performance of the contract. At the same time, inadequate audit processes could also land you up with risks such as loss of personal data or data theft.</p>
                                <div className="readmore-btn">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                <AboutTwo />
                <Separator />
                
                {/* Start Service Area  */}
                <div className="service-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Did you fork an existing smart contract and need an offiical code review?</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Cipher Labs smart contract auditors will review and compare your forked contracts with the orginals, run your unit and other tests and report our findings.</p>
                                <div className="readmore-btn">
                                    <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
               
                <Footer />
                
            </main>
        </>
    )
}
export default Auditing;
