import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Home from "./pages/Home";
import Dao from "./pages/Dao";
import Auditing from "./pages/Auditing";
import Consulting from "./pages/Consulting";
import Blockchains from "./pages/Blockchains";
import Dapps from './pages/Dapps';
import Defi from './pages/Defi';

// Elements import Here 
import Error from "./pages/Error";
import Contact from "./elements/contact/Contact";


// Import Css Here 
import './assets/scss/style.scss';



const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/dao"}`} exact component={Dao}/>
                    <Route path={`${process.env.PUBLIC_URL + "/auditing"}`} exact component={Auditing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/consulting"}`} exact component={Consulting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blockchains"}`} exact component={Blockchains}/>
                    <Route path={`${process.env.PUBLIC_URL + "/dapps"}`} exact component={Dapps}/>
                    <Route path={`${process.env.PUBLIC_URL + "/defi"}`} exact component={Defi}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
