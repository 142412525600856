import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabEvm = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-01.png' alt="Decentralized Autonomous Organization" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>QBFT</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Permissioning</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Zero Gas Networks</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>In QBFT networks, approved accounts, known as validators, validate transactions and blocks. Validators take turns to create the next block. Before inserting the block onto the chain, a super-majority (greater than or equal to ⅔) of validators must first sign the block.</p>

                                                        <p>QBFT provides two methods to manage validators:</p> 
                                                            <p>1) Existing validators propose and vote to add or remove validators using the QBFT JSON-RPC API methods.</p> 
                                                            <p>2) Use a smart contract to specify the validators used to propose and validate blocks.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Onchain permissioning uses smart contracts to store and administer the node, account, and admin allowlists. Using onchain permissioning enables all nodes to read the allowlists from a single source, the blockchain.</p>

                                                        <p>When using onchain account permissioning, a node checks permissions when importing blocks. Meaning, a node only imports blocks in which all transactions are from authorized senders. You can update nodes and accounts allowlists using the Besu permissioning management dapp.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Transactions use computational resources so have an associated cost. Gas is the cost unit and the gas price is the price per gas unit. The transaction cost is the gas used * gas price.</p>
                                                        <p>In most networks, the account submitting the transaction pays the transaction cost, in Native Tokens. The validator in a PoA network that includes the transaction in a block receives transaction cost.</p>
                                                        <p>In some use cases, network participants running validators and do not require gas as an incentive. Networks that don’t require gas as an incentive usually configure the gas price to be zero (that is, free gas).</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabEvm
