import React from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";
import {useEffect} from 'react';

const ContactCal = 
    function MyComponent() {
  useEffect(()=>{
    (async function () {
      const cal = await getCalApi();
      cal("ui", {"styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false});
    })();
  }, [])
  return <Cal calLink="cipherlabs/" style={{width:"100%",height:"100%",overflow:"scroll"}} />;
};

export default ContactCal;