import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutDefi = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-audits.png" alt="defi" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Decentralized Finance</span></h4>
                                    <h2 className="title mt--10">DeFi</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Are you ready to be a part of the future of finance? Decentralized finance, also known as DeFi, is taking the Web3 world by storm and it's only getting bigger. With its innovative and practical applications, it's no wonder why DeFi is one of the most talked-about trends in the Web3 space. Imagine the possibilities of how Web3 technology can revolutionize industries like financial services. Don't miss out on this opportunity to be at the forefront of this exciting movement - join the DeFi revolution today!</p>

                                    <p>With cutting-edge products and services, DeFi is revolutionizing the way we access financial options. From automated market makers and DEXes for efficient crypto trading, to lending and borrowing protocols for increased accessibility, DeFi is changing the game. And that's not all - DeFi is also introducing new forms of investing, like liquidity mining, making it more attractive than ever. Don't miss out on the opportunity to join the DeFi movement and take control of your financial future. Upgrade to DeFi today!</p>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Get Started <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutDefi;
