import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutEvm = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-audits.png" alt="smart contract auditor" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Built with Hyperledger Besu</span></h4>
                                    <h2 className="title mt--10">Enterprise Features</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Your network will support smart contract and dapp development using tools such as Hardhat, Truffle, Remix, and web3j.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> The Ethereum Virtual Machine (EVM) allows the deployment of smart contracts.</li>
                                        <li><span className="icon"><FiCheck /></span> QBFT Proof of Authority (PoA) is an enterprise-grade consensus protocol.</li>
                                        <li><span className="icon"><FiCheck /></span> JSON-RPC APIs over HTTP and WebSocket protocols as well as a GraphQL API.</li>
                                        <li><span className="icon"><FiCheck /></span> Common JSON-RPC API methods such as eth, net, web3, debug, and miner.</li>
                                        <li><span className="icon"><FiCheck /></span> Network & Node performance monitoring using Prometheus & Grafana.</li>
                                        <li><span className="icon"><FiCheck /></span> Private Transaction Manager to keep transactions private.</li>
                                        <li><span className="icon"><FiCheck /></span> Permissioning for specified nodes and accounts to participate on the network.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Get Started <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutEvm;
