import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import About from '../elements/about/About';
import ServiceTwo from '../elements/service/ServiceTwo';
import BrandFour from "../elements/brand/BrandFour";
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import Team from '../elements/team/Team';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";





const Home = () => {
    return (
        <>
            <SEO title="Cipher Labs" />
            <main className="page-wrapper">
                
                <Header btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">BLOCKCHAIN CONSULTING & DEVELOPMENT</span>
                                    <h1 className="title theme-gradient display-two">Cipher Labs <br /> {" "}
                                        <Typed
                                            strings={[
                                                "EVM Blockchains.",
                                                "Web3 Aplications.",
                                                "Smart Contracts.",
                                                
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Cipher Labs is a research focused consulting firm helping clients using our unique knowledge and position since 2017.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://git.cipherlabs.org/explore/repos">Our Projects<i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default btn-medium btn-border round btn-icon" target="_blank" href="https://cal.com/cipherlabs">Schedule a Meeting<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start About Area  */}
                <a id="about"></a><About />                           
                {/* End About Area  */}

                <Separator />                            
               {/* Start Service Area  */}
               <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Web3 Development Services"
                                        description = "Cipher Labs is a Full Stack Blockchain Development Firm, <br /> We offer affordable rates for projects big and small."
                                     />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}
                {/*    
                {/* Start Brand Area  
                    <div className="rwt-brand-area rn-section-gap"> 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Awesome Tech"
                                        title = "Tech We Work With."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandFour brandStyle="brand-style-2 variation-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area  

                


                {/* Start Call To Action Area  
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Call To Action Area  */}


                 {/* Start Team Area  
                 <a id="team"></a>
                 <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts."
                                        title = "Our Company Experts."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <Team column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                    </div>
                </div>
                {/* End Team Area  */}
                <Separator /> 
                <a id="contact"></a>
                <Footer />
                
            </main>
        </>
    )
}
export default Home;
