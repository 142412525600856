import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
{
image: '/images/service/dao.png',
title: 'Decentralized Organizations (DAOs)',
description: 'Looking to Launch a DAO and need a tech partner? ',
link: '/dao'
},
{
image: '/images/service/defi.png',
title: 'Decentralized Finance (DeFi) Applications',
description: 'We build DEXs and other finance protocols.',
link: '/defi'
},
{
image: '/images/service/service-04.png',
title: 'Decentralized Applications (dApps)',
description: 'dApps come in all shapes and sizes we can help you.',
link: '/dapps'
},
{
image: '/images/service/service-02.png',
title: 'Start Contract Development & Auditing',
description: 'We offer development audits and fork reviews.',
link: '/auditing'
}
];

const ServiceTwo = ({ textAlign, cardStyle }) => {
return (
<div className="row row--15 service-wrapper">
{ServiceList.map((val, i) => (
<div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
<ScrollAnimation
                     animateIn="fadeInUp"
                     animateOut="fadeInOut"
                     animateOnce={true}>
<div className={'card-box ${cardStyle} ${textAlign}'}>
<div className="inner">
<div className="image">
<Link to={val.link}>
<img src={val.image} alt="web3 development" />
</Link>
</div>
<div className="content">
<h4 className="title mb--20">
<Link to={'${val.link}'} dangerouslySetInnerHTML={{ __html: val.title }}></Link>
</h4>
<p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p><br></br>
<Link className="btn-default btn-small btn-border" to={val.link}>Read More</Link>
</div>
</div>
</div>
</ScrollAnimation>
</div>
))}
</div>
);
};

export default ServiceTwo;