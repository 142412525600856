import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionBC = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    How many years of experience does Cipher Labs have?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>The founders of Cipher Labs have been developing blockchains, dApps and smart contracts since 2015.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Can you clone or fork 'xyz' crypto project for me?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>More than likely yes however not all crypto projects are open source. Closed source projects and smart contracts require additional custom development.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Can you also advise on non-EVM chains like Bitcoin or DASH?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes, if your use case does not require smart contracts we can consult on and develop Bitcoin based blockchains with Proof of Stake solutions to avoid energy consumption from miners.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What time zone are you in?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Cipher Labs regular business hours are Monday though Friday from 9:00 AM EST to 5:00 PM EST</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionBC

