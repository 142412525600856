import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import AboutDao from '../elements/about/AboutDao';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import TabDao from '../elements/tab/TabDao';
var BlogListData = BlogClassicData.slice(0, 3);



const Dao = () => {
    return (
        <>
            <SEO title="Decentralized Autonomous Organization (DAOs)" />
            <main className="page-wrapper">
                
                <Header btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h2 className="title theme-gradient display-two">Decentralized Autonomous<br />Organizations (DAOs)</h2>
                                    <p className="description">We help our clients succeed by creating DAOs for Web3 community projects, and digital organizations.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon" target="_blank" href="https://cal.com/cipherlabs">Learn More <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./images/about/about-2.png" alt="DAO Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "About DAOs."
                                    title = "Decentralized Autonomous Organizations."
                                    description = "Community driven digital organizations."
                                />
                            </div>
                        </div>
                        <TabDao />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                
                <AboutDao />

                <Separator />
                
                
                <Footer />
            </main>
            
        </>
    )
}
export default Dao;