import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-audits.png" alt="smart contract auditor" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Smart Contract Audits.</span></h4>
                                    <h2 className="title mt--10">Our Audit Process.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Our smart contract audits include automated tools and manual analysis of the source code against all known vulnerabilities, an initial audit report with found issues and recommendations on how to fix them, validation of remediations and a final audit report.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Manual audit includes checking the code line by line for flaws.</li>
                                        <li><span className="icon"><FiCheck /></span> Testing and executing the code by deploying it on a testnet.</li>
                                        <li><span className="icon"><FiCheck /></span> Automated auditing using auditing tools like Slither and Mythril.</li>
                                        <li><span className="icon"><FiCheck /></span> Black Box testing including fuzzing, automated flawed, and random data injection.</li>
                                        <li><span className="icon"><FiCheck /></span> Reporting, generally done in two phases: Initial and Final Report.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Get Started <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
