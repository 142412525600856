import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
//import TimelineBC from '../elements/timeline/TimelineBC';
import TabBC from '../elements/tab/TabBC';
import AboutBC from '../elements/about/AboutBC';
import PricingThree from '../elements/pricing/PricingThree';
//import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);



const BC = () => {
    return (
        <>
            <SEO title="Blockchain and Web3 Consulting" />
            <main className="page-wrapper">
                
                <Header btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h2 className="title theme-gradient display-two">Blockchain<br />Consulting</h2>
                                    <p className="description">Our Web3 consulting services can help you understand the opportunities and risks of using blockchain and Web3 to develop your business ideas.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon" target="_blank" href="https://cal.com/cipherlabs">Schedule A Meeting <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./images/about/about-consulting.png" alt="blockchain consulting" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Blockchain Consulting."
                                    title = "Let our expertise be your guide."
                                    description = "We have helped hundreds of clients understand the complexities of blockchain<br /> and how they can be applied to thier businesses and projects."
                                />
                            </div>
                        </div>
                        <TabBC />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                <AboutBC />

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--35">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Blockchain Consulting"
                                    title = "Our Pricing"
                                    description = ""
                                />
                            </div>
                        </div>
                        <PricingThree />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                
                <Footer />
            </main>
            
        </>
    )
}
export default BC;