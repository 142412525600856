import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import TabEvm from '../elements/tab/TabEvm';
import AboutEvm from "../elements/about/AboutEvm";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import AboutBD from '../elements/about/AboutBD';
var BlogListData = BlogClassicData.slice(0, 3);



const Blockchains = () => {
    return (
        <>
            <SEO title="Blockchain Development" />
            <main className="page-wrapper">
                
                <Header btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h2 className="title theme-gradient display-two">Blockchain<br />Development (EVMs)</h2>
                                    <p className="description">Cipher Labs Builds Enterprise Grade Layer 1 EVM Compatible Blockchains. We Deploy Both Public and Private Networks.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <Link className="btn-default btn-medium btn-icon" to="/contact">Learn More <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./images/about/about-2.png" alt="EVM Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                <AboutEvm />

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "About Our EVM Soltions."
                                    title = "PoA, Validators, Gas."
                                    description = "QBFT proof of authority (PoA) consensus protocol."
                                />
                            </div>
                        </div>
                        <TabEvm />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Timeline"
                                        title = "Deployment Process."
                                        description = "Est 4 to 6 Week Depolyment Time with Testnet, Mainnet and Blockchain Explorers."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                <AboutBD />

                <Separator />
                
                <Footer />
            </main>
            
        </>
    )
}
export default Blockchains;