import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutDapps = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-audits.png" alt="smart contract auditor" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Decentralized Applications</span></h4>
                                    <h2 className="title mt--10">DApps</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Are you ready for the future of applications? Decentralized applications, or DApps, are the next big thing in the world of software. Built on cutting-edge blockchain technology, DApps are open-source and run on a peer-to-peer network of servers. With the ability to be built on multiple blockchain platforms like Ethereum, BSC, Polygon, AVAX and more, DApps offer a level of reliability that traditional apps cannot match. DApps eliminate the need for central servers, giving you more control and security over your data. Get ahead of the curve and experience the power of DApps today!</p>

                                    <p>DApps offer the ultimate solution. With increased freedom, openness, and transparency, DApps remove intermediaries, giving you more control over your data. Not only that, but DApps also carry lower risks of mishandling user data. While developing a DApp may be a complex process that requires significant attention and effort, the benefits make it worth it. Once deployed, you will enjoy a level of security and freedom that traditional apps cannot match.</p>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Get Started <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutDapps;
