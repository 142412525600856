import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionDapps = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Can you clone 'xyz' project for me?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>More than likely yes however not all crypto projects are open source. Closed source projects and smart contracts require additional custom development.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Where are DApps Hosted?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>A DApp can be hosted on a single cloud server instance. To be truly decentralized a DApp should be hosted on multiple servers and services.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    What Blockchain can you deploy my DApp on?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>We work with most EVM compatible blockchains including Ethereum, Polygon, BSC, AVAX and others. We can also build multi-chain DApps. We do not work with Solana or smart contracts written in RUST.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    How much will it cost to develop a DApp?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The cost of developing a dApp depends on the vision and what you are trying to achieve from it. The cost is affected by other factors like the industry, complexity, and the number of users interacting with DApp at a given time.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionDapps

