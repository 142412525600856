import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingThree = () => {
    return (
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <div className="row row--0">
                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">40 Minutes</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">120</span>
                                        </div>
                                        <span className="subtitle">USD Per Session</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Group Call or 1-on-1</li>
                                        <li><FiCheck /> Great for Small Projects</li>
                                        <li><FiCheck /> Crypto Accepted</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a className="btn-default btn-border" target="_blank" href="https://cal.com/cipherlabs/40-min-meeting">Purchase Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}

                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2 active">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">1 Hr 30 Min</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">200</span>
                                        </div>
                                        <span className="subtitle">USD Per Session</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Group Call or 1-on-1</li>
                                        <li><FiCheck /> Great for Larger Projects</li>
                                        <li><FiCheck /> After Call Report</li>
                                        <li><FiCheck /> Crypto Accepted</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a className="btn-default" target="_blank" href="https://cal.com/cipherlabs/90-min-consultation">Purchase Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}
                </div>
            </div>
        </div>
    )
}
export default PricingThree;
