import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabBC = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-bc.png' alt="blockchain consultants" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Knowledge</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Strategy</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Development</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Our approach covers the full end-to-end spectrum from strategy to execution. To do this we must first understand your vision, goals and the expectations. Our first meeting will be to gain insight of what you want to acomplish.</p>

                                                        <p>We aim to help our clients understand how old Web2 business processes and models convert to Web3 technology. We can answer all your blockchain and Web3 related questions and provide some initial suggestions and ideas.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>After assesing your obejectives Cipher Labs can help you plan your development strategy. We will break down the phases, order and expected timeline for development, testing and launching your blockchain or Web3 project.</p>

                                                        <p>Carefull planning and adequate testing can mean the differance between a successful project launch or failure. Cipher Labs can help you in every step for building and executing your strategy.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Once we have a defined project plan and strategy the development process can start. We start with creating a private code repository where your projects source code is kept. We use our own private git server and github.</p>

                                                        <p>Clinet - Developer communication is essential to successful and timely project completion. During the development process clinets will have direct accesss to Cipher Labs via our discord server.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabBC
