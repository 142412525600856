import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link></li>
                        
            <li className="has-droupdown"><Link to="#">Blockchain Development</Link>
                <ul className="submenu">
                    <li><Link to="/consulting">Blockchain Consulting</Link></li>
                    <li><Link to="/blockchains">Blockchain Development</Link></li>
                    <li><Link to="/#">Blockchain-as-a-Service (BaaS) (Coming Soon)</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="#">Web3 Development</Link>
                <ul className="submenu">
                    <li><Link to="/dao">Decentralized Autonomous Organization (DAOs)</Link></li>
                    <li><Link to="/dapps">Decentralized Applications (DApps)</Link></li>
                    <li><Link to="/defi">Decentralized Finance (DeFi)</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="#">Audits & KYC</Link>
                <ul className="submenu">
                    <li><Link to="/auditing">Smart Contract Audits</Link></li>
                    <li><Link to="/#">KYC (Coming Soon)</Link></li>
                </ul>
            </li>           

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
