import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionDao = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What Is the Purpose of a DAO?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>A DAO intends to give every member of an organization a voice, vote, and opportunity to propose initiatives. A DAO also strives to have strict governance that is dictated by code on a blockchain and member controled treasuries.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    How Does a DAO Make Money?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>A DAO can initially raise capital by selling its native token. This native token represents voting power and ownership proportion across members. If a DAO is successful, the value of the native token will increase. A DAO can also invest in assets if the members decide to approve such measures.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Is there any legal status for DAOs?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes, currently Wyoming, Vermont, and the Virgin Islands have DAO laws in some form that offer a legal status for DAOs.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What else can a DAO do?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>This solo depends on the membership collective and the DAO's goals. Some famous examples are CityDAO used Wyoming's DAO law to buy 40 acres of land near Yellowstone National Park. In 2021, ConstitutionDAO was formed in an attempt to buy a copy of the U.S. Constitution.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionDao

