import React from 'react';
import CalltoActionOne from "../../elements/calltoaction/CalltoActionOne";
import Copyright from "./Copyright";
import ScrollTop from "./ScrollTop";


const Footer = () => {
    return (
        <>
            <div className="footer-style-2">
                <CalltoActionOne />
                <Copyright />
            </div>
            <ScrollTop />
        </>
    )
}
export default Footer;