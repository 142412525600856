import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import AboutDao from '../elements/about/AboutDao';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import TabDao from '../elements/tab/TabDao';
import AboutDapps from '../elements/about/AboutDapps';
import AboutDapps2 from '../elements/about/AboutDapps2';
var BlogListData = BlogClassicData.slice(0, 3);



const Dapps = () => {
    return (
        <>
            <SEO title="Decentralized Applications (DApps)" />
            <main className="page-wrapper">
                
                <Header btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h2 className="title theme-gradient display-two">Decentralized <br />Applications (DApps)</h2>
                                    <p className="description">We help our clients convert thier ideas into functional Decentralized Applications.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon" target="_blank" href="https://cal.com/cipherlabs">Learn More <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./images/about/about-2.png" alt="DAPP Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                <AboutDapps />
               
                <Separator />
                
                <AboutDapps2 />

                <Separator />
                
                
                <Footer />
            </main>
            
        </>
    )
}
export default Dapps;